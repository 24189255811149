import Swiper from 'swiper';
import { Autoplay } from 'swiper/js/swiper.esm';


$(document).ready(function () {


    var cardSlider = $('.JS-3-services-slider');
    if ($(cardSlider).length != 0) {
        cardSlider.each(function () {
            var cardSliderWrapper = $(this).closest('.s-3-services__slider');
            var sliderNavPrev = $(this).find('.swiper-button-prev');
            var sliderNavNext = $(this).find('.swiper-button-next');
            var recipesSwiper = new Swiper($(this)[0], {
                slidesPerView: 3,
                spaceBetween: 0,
                navigation: {
                    nextEl: sliderNavNext,
                    prevEl: sliderNavPrev,
                },
                breakpoints: {
                    0: {
                        slidesPerView: 1,
                    },
                    375: {
                        slidesPerView: 1.22,
                        slidesOffsetBefore: 30,
                        slidesOffsetAfter: 30,
                    },
                    768: {
                        slidesPerView: 1.58,
                        slidesOffsetBefore: 25,
                        slidesOffsetAfter: 25,
                    },
                    992: {
                        slidesPerView: 2.2,
                        slidesOffsetBefore: 25,
                        slidesOffsetAfter: 25,
                    },
                    1290: {
                        slidesPerView: 3,
                        slidesOffsetBefore: 0,
                        slidesOffsetAfter: 0,
                    },
                },
                on: {
                    transitionStart: function () {
                        if (sliderNavNext.hasClass('swiper-button-disabled')) {
                            $('.swiper-slide').removeClass('swiper-slide-active');
                            $('.swiper-slide:last-child').addClass('swiper-slide-active');
                        } else {
                            $('.swiper-slide:last-child').removeClass('swiper-slide-active');
                        }
                    },
                }
            });
            
            function resizeScreen() {
                if ($(window).width() > 1290){
                    $(cardSliderWrapper).find('.swiper-wrapper').addClass("u-disabled");
                }
                else {
                    $(cardSliderWrapper).find('.swiper-wrapper').removeClass("u-disabled");
                }
            }

            resizeScreen();

            $(window).resize(function () {
                resizeScreen();
            });
        })
    }

    var testimonialsSlider = $('.JS-testimonials-slider');
    if ($(testimonialsSlider).length != 0) {
        testimonialsSlider.each(function () {
            var sliderNavPrev = $(this).find('.swiper-button-prev');
            var sliderNavNext = $(this).find('.swiper-button-next');
            var sliderPagination = $(this).find('.swiper-pagination');
            var sliderContainer = $(this).find('.swiper-container');
            var testimonialsSwiper = new Swiper((sliderContainer), {
                noSwipingClass: 'swiper-no-swiping',
                pagination: {
                    el: sliderPagination,
                    type: 'fraction',
                },
                navigation: {
                    nextEl: sliderNavNext,
                    prevEl: sliderNavPrev,
                },
            })
        })
    }

    var stepsSlider = $('.JS-steps-slider');
    if ($(stepsSlider).length != 0) {
        stepsSlider.each(function () {
            var stepsScrollbar = $(this).find('.swiper-scrollbar');
            var sliderContainer = $(this).find('.swiper-container');
            var stepsSwiper = new Swiper((sliderContainer), {
                direction: 'vertical',
                slidesPerView: 'auto',
                freeMode: true,
                slidesOffsetAfter: 200,
                cssMode: true,
                scrollbar: {
                    el: stepsScrollbar,
                    draggable: true,
                    dragSize: 200,
                    snapOnRelease: false
                },
                mousewheel: {
                    eventsTarget: '.c-steps__slider',
                },
                breakpoints: {
                    768:{
                        cssMode:false,
                    }
                }             
            });

            function resizeScreen() {
                if ($(window).width() < 768){
                    $(sliderContainer).find('.swiper-wrapper').addClass("u-disabled");
                    stepsSwiper.mousewheel.disable();
                }
                else {
                    $(sliderContainer).find('.swiper-wrapper').removeClass("u-disabled");
                    stepsSwiper.mousewheel.enable();
                }
            }

            resizeScreen();

            $(window).resize(function () {
                resizeScreen();
            });
        })
        
    }

    var languageSlider = $('.JS-language-slider');
    if ($(languageSlider).length != 0) {
        languageSlider.each(function () {
            var languageGalleryTop = $(this).find('.JS-language-slider-top');
            var languageGalleryThumb = $(this).find('.JS-language-thumbs');
            var languageSliderLenhth = languageGalleryTop.find('.swiper-slide').length;
            var sliderNavPrev = $(this).find('.swiper-button-prev');
            var sliderNavNext = $(this).find('.swiper-button-next');

            var galleryThumbs = new Swiper(languageGalleryThumb, {
                slidesPerView: 'auto',
                centeredSlides: true,
                slideToClickedSlide: true,
                loop: true,
                loopedSlides: languageSliderLenhth, //looped slides should be the same
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                breakpoints: {

                    1440: {
                        slidesPerView: 8,
                        centeredSlides: false,
                    }

                }
              });
              var galleryTop = new Swiper(languageGalleryTop, {
                effect: "fade",
                fadeEffect: {
                    crossFade: true
                },
                spaceBetween: 0,
                grabCursor: false,
                loop:true,
                loopedSlides: languageSliderLenhth, //looped slides should be the same
                navigation: {
                  nextEl: sliderNavNext,
                  prevEl: sliderNavPrev,
                },
              });

              galleryTop.controller.control = galleryThumbs;
              galleryThumbs.controller.control = galleryTop;
        })
    }

    var achievementsSlider = $('.JS-achievements-slide');
    if ($(achievementsSlider).length != 0) {
        achievementsSlider.each(function () {
            var sliderNavPrev = $(this).find('.swiper-button-prev');
            var sliderNavNext = $(this).find('.swiper-button-next');
            var sliderPagination = $(this).find('.swiper-pagination');
            var sliderContainer = $(this).find('.swiper-container');
            var achievementsSwiper = new Swiper((sliderContainer), {
                effect: 'coverflow',
                grabCursor: true,
                centeredSlides: true,
                slidesPerView: "auto",
                loop: true,
                coverflowEffect: {
                  rotate: 0,
                  stretch: 169,
                  depth: 300,
                  modifier: 1,
                  slideShadows : false,
                },
                navigation: {
                    nextEl: sliderNavNext,
                    prevEl: sliderNavPrev,
                },
                
            })
        })
    }
});

