
$(document).ready(function () {
    $('.wpcf7-select').each(function() {
        const _this = $(this),
            selectOption = _this.find('option'),
            selectOptionLength = selectOption.length,
            selectedOption = selectOption.filter(':selected'),
            selectLabel = $('label.JS-select-label'),
            duration = 150;
            
        _this.hide();
        _this.wrap('<div class="select"></div>');
        $('<div>', {
            class: 'new-select',
        }).insertAfter(_this);
    
        const selectHead = _this.next('.new-select');
        $('<div>', {
            class: 'new-select__list'
        }).insertAfter(selectHead);
    
        const selectList = selectHead.next('.new-select__list');
        for (let i = 0; i < selectOptionLength; i++) {
            $('<div>', {
                class: 'new-select__item',
                html: $('<span>', {
                    text: selectOption.eq(i).text()
                })
            })
            .attr('data-value', selectOption.eq(i).val())
            .appendTo(selectList);
        }

        const selectItem = selectList.find('.new-select__item');
        selectList.slideUp(0);
        selectHead.on('click', function() {
            if ( !$(this).hasClass('on') ) {
                $(this).addClass('on');
                selectList.slideDown(duration);
    
                selectItem.on('click', function() {
                    let chooseItem = $(this).data('value');
    
                    $('select').val(chooseItem).attr('selected', 'selected');
                    selectHead.text( $(this).find('span').text() );
    
                    selectList.slideUp(duration);
                    selectHead.removeClass('on');
                    selectLabel.addClass('select-label-focus');
                });
    
            } else {
                $(this).removeClass('on');
                selectList.slideUp(duration);
            }
        });
    });


    var navToggle = $('#navToggle');
    if (navToggle.length !== 0) {
        navToggle.each(function () {
            var navWrapper = $('.c-main-menu');
            $(this).on('click', function () {
                $(this).toggleClass('active-an');
                navWrapper.toggleClass('menu-active');
                $('.o-header').toggleClass('open');
                $('body').toggleClass('u-overflow');
            });
        });
    }
    

    $(window).on('resize', function(){
        var win = $(window).width();
        if (win >= 992) {
            //console.log('test');
            $('.c-main-menu').removeClass('active-an');
            $('.c-main-menu').removeClass('menu-active');
            $('.o-header').removeClass('open');
            $('body').removeClass('u-overflow');
            $('.navBurger').removeClass('active-an');
        }
    });

    $(window).scroll(function(){
        var headerOffset = $('.o-header').offset();
        var headerTopOffset = headerOffset.top;
        var topMenuHeight = $('.o-header__top').outerHeight();
        var stickyMenuOffset = headerTopOffset + topMenuHeight;
        var stickyMenuHeight = $('.o-header__main').outerHeight();        
        var stickyMenu = $('.o-header__main');
        var scroll = $(window).scrollTop();
      
        if (scroll >= stickyMenuOffset) {
            stickyMenu.addClass('fixed');
            $('.o-header').css('margin-bottom', (stickyMenuHeight));
        } else {
            stickyMenu.removeClass('fixed');
            $('.o-header').css('margin-bottom', (0));
        }
      });

/*      $('.menu-item-has-children').children('a').click(function( event ) {
        event.preventDefault();
    });*/
});