$(document).ready(function () {

    var faqTitles = $(".JS--faq-title");
    if (faqTitles.length !== 0) {
        faqTitles.each(function () {
            $(this).on('click', function () {
                $(this).parent().siblings('.JS--faq-item').find('.JS--faq-title').removeClass('is-active');
                $(this).siblings('.JS--faq-content:visible').slideUp();
                $(this).toggleClass('is-active');
                if ($(this).hasClass('is-active')) {
                    $(this).next().slideDown();
                }
                $(this).parent().siblings('.JS--faq-item').find('.JS--faq-content').slideUp();
            });
        });
    }


});