$(document).ready(function () {

    var menuDropdownTriggers = $('.JS--submenu-open');

    if(menuDropdownTriggers.length !== 0){
        menuDropdownTriggers.each(function () {
            $(this).on('click', function () {
                $(this).parent().toggleClass('is-active');
                $(this).parent().siblings('.menu-item-has-children').removeClass('is-active');
                $(this).parent().siblings('.menu-item-has-children').find('.JS--submenu-open').removeClass('is-active');
                $(this).siblings('.sub-menu:visible').slideUp();
                $(this).toggleClass('is-active');
                if ($(this).hasClass('is-active')) {
                    $(this).next().slideDown();
                }

                $(this).parent().siblings('.menu-item-has-children').find('.sub-menu').slideUp();
            });
        });
    }


});