global.$ = global.jQuery = require('jquery');


require('./components/global');
require('./components/menu-dropdown');
require('./components/video');
require('./components/slider');
require('./components/faq');
require('./components/contact-form');

require('./components/animations');