import videojs from 'video.js';
import youtube from 'videojs-youtube';

function videoSection(item, index){
    var btn = videoBtn[index];
    var id = btn.getAttribute('data-id');

    btn.addEventListener("click", function () {
        videojs(id).play();
    });

    videojs(id).on('play', function () {
        btn.classList.remove('is-active');
        videojs(id).controls(true);
    });

    videojs(id).on('pause', function () {
        btn.classList.add('is-active');
        videojs(id).controls(false);
    });
}

var videoBtn = document.querySelectorAll('.js-video-btn');

for(var i = 0 ;i < videoBtn.length ; i++){
    videoSection(videoBtn[i],i);
}


