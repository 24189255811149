$(document).ready(function () {

    var wpcf7Form = $('.wpcf7');

    if(wpcf7Form.length !== 0){
        // on focus
        $(".wpcf7-form input,.wpcf7-form textarea").focus(function() {
            $(this).parent().siblings('label').addClass('has-value');
        })
        // blur input fields on unfocus + if has no value
            .blur(function() {
                var text_val = $(this).val();
                if(text_val === "") {
                    $(this).parent().siblings('label').removeClass('has-value');
                }
            });

        var wpcf7Elm = document.querySelector( '.wpcf7' );

        wpcf7Elm.addEventListener( 'wpcf7invalid', function( event ) {

            var invalidInputs = $('.wpcf7-not-valid');
            $('.wpcf7-form-control').closest('p').find('label').removeClass('error-label');
            invalidInputs.each(function(){
                $(this).parent().closest('p').find('label').addClass('error-label');
            });

        }, false );

       /* wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
            $('.wpcf7-form-control').parent().closest('p').find('label').removeClass('error-label has-value');
            location = 'http://ideal-hh/thanks-page/';
        }, false );*/
    }


});